/**
 * Use the promotional rail to feature 5+ promoted stories that are related. For example, Beauty steals during a major event. Use when a higher prominence and/or creative image is needed.
 *
 * @module views/components/PromotionalRail
 * @memberof -Common
 */
import './PromotionalRail.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, {
  A11y,
  Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '@ulta/core/components/Button/Button';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import carouselUtils, { handleInactiveSwiperSlides } from '@ulta/utils/carouselUtils/carouselUtils';

import Article from '../Article/Article';
// install Swiper modules
SwiperCore.use( [Navigation, Keyboard, A11y] );

let swiperCounter = 1;
/**
 * Represents a PromotionalRail component
 *
 * @method
 * @param {PromotionalRailProps} props - React properties passed from composition
 * @returns PromotionalRail
 */
export const PromotionalRail = function( props ){
  const {
    previousAccessibility,
    nextAccessibility,
    backgroundColor,
    patternBackground,
    linkAction,
    carouselDetailsAccessibility,
    carouselAccessibility,
    title,
    articles,
    rootMargin,
    root,
    threshold,
    previousClickAction,
    nextClickAction
  } = props;
  const promotionalRailRef = useRef( null );
  swiperCounter += 1;
  useIntersectionObserver( promotionalRailRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  return (
    <div className={
      classNames( 'PromotionalRail', {
        'PromotionalRail__withBackgroundColor': backgroundColor
      } )
    }
    ref={ promotionalRailRef }
    >
      <GridContainer
        { ...( !patternBackground && { fullBleedContentBackgroundColor: backgroundColor } ) }
      >
        <div className='PromotionalRail__header'>
          { props.title &&
            <div className='PromotionalRail__Sectiontitle'>
              <Text
                textStyle='title-6'
                htmlTag='h2'
              >
                { props.title }
              </Text>
            </div>
          }
          { linkAction?.url && linkAction?.label &&
            <Link_Huge
              withHover
              action={ linkAction }
            >
              { linkAction.label }
            </Link_Huge>
          }
        </div>
        <div className='PromotionalRail__carousel'
          role='region'
          aria-label={ carouselUtils.handleTokenizedString( carouselAccessibility, [title] ) }
        >

          { carouselDetailsAccessibility &&
            <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibility, [title] ) }</p>
          }
          <div className={ `PromotionalRail__carousel__Pagination PromotionalRail__carousel__Pagination-${swiperCounter}` }>
            <Button
              variant='navigation'
              iconImage='CaretBack'
              iconSize='lg'
              className='PromotionalRail__carousel__Pagination__button PromotionalRail__carousel__Pagination__button--back'
              ariaLabel={ previousAccessibility }
              ariaHiddenIcon={ true }
              action={ previousClickAction }
            />

            <Button
              variant='navigation'
              iconImage='CaretForward'
              iconSize='lg'
              className={ 'PromotionalRail__carousel__Pagination__button PromotionalRail__carousel__Pagination__button--forward' }
              ariaLabel={ nextAccessibility }
              ariaHiddenIcon={ true }
              action={ nextClickAction }
            />
          </div>

          <Swiper
            onSwiper={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
            onSlideChange={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
            freeMode={ true }
            watchOverflow={ true }
            watchSlidesProgress={ true }
            watchSlidesVisibility={ true }
            speed={ 600 }
            preloadImages={ true }
            spaceBetween={ 16 }
            navigation={ {
              prevEl: `.PromotionalRail__carousel__Pagination-${swiperCounter} .PromotionalRail__carousel__Pagination__button--back`,
              nextEl: `.PromotionalRail__carousel__Pagination-${swiperCounter} .PromotionalRail__carousel__Pagination__button--forward`,
              disabledClass: `PromotionalRail__carousel__Pagination__button--disabled`
            } }
            breakpoints={ {
              993: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              577: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              0: {
                slidesPerView: 1.6,
                slidesPerGroup: 2
              }
            } }
          >
            { articles?.length > 0 && articles.map( ( product, productIndex )=>(
              <SwiperSlide key={ `PromotionalRail${ productIndex + 1 } ` }>
                <div role='group'>
                  <Article
                    { ...product }
                    key={ productIndex }
                    backgroundColor={ backgroundColor }
                    promotionalCardAccessibilityKey={ productIndex }
                  />
                </div>
              </SwiperSlide>
            ) )
            }
          </Swiper>
        </div>
      </GridContainer>
    </div>
  );
};

/**
 * Default values for passed properties
 * @type {PromotionalRailProps}
 * @property {bool} railAccessibility='carousel' - The default value for aria-label is set to text 'carousel'.
 */
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};
/**
 * Property type definitions
 *
 * @typedef PromotionalRailProps
 * @type {object}
 * @property {string} title - Sets the headline title.
 * @property {string} previousAccessibility - Sets the aria label for previous button.
 * @property {string} nextAccessibility - Sets the aria label for next button.
 * @property {object} previousClickAction - This is the action object for the Next button.
 * @property {object} nextClickAction - This is the action object for the Previous button.
 * @property {object} linkAction - Sets the linkAction.
 * @property {array} articles - Set the Item array in articles.
 * @property {string} backgroundColor - Sets the backgroundColor.
 * @property {string} carouselAccessibility - Value for aria label for carousel.
 * @property {string} carouselDetailsAccessibility - Value for sr-only description.
 *
 */
export const propTypes =  {
  title: PropTypes.string,
  previousAccessibility: PropTypes.string,
  nextAccessibility: PropTypes.string,
  nextClickAction: PropTypes.object,
  previousClickAction: PropTypes.object,
  linkAction: PropTypes.object,
  articles: PropTypes.array,
  backgroundColor: PropTypes.string,
  carouselAccessibility: PropTypes.string,
  carouselDetailsAccessibility: PropTypes.string
};

PromotionalRail.propTypes = propTypes;
PromotionalRail.defaultProps = defaultProps;

export default PromotionalRail;
